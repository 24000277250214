import L from 'leaflet';
import queryString from 'query-string';
import AppContext from '../models/AppContext';
import Area from '../models/Area';
import { createMetadataContent, addNavigationEvents } from './metadata-helper';
import LayerGroup from '../models/LayerGroup';
import LeafletDrawEvents from '../models/LeafletDrawEvents';
import LeafletDrawShape from '../models/LeafletDrawShape';
import Coordinate from '../models/Coordinate';

const swedenBoundingBox : L.LatLngBoundsExpression = [
    [55.3617373725, 11.0273686052],
    [69.1062472602, 23.9033785336]
];

export interface MapQuerystringParameters {
    lat?: number,
    lng?: number,
    zoom?: number,
    background?: string,
    layers?: string
};

export const getMapQuerystringParameters = (map: L.Map | undefined, appContext: AppContext | undefined) : MapQuerystringParameters =>  {
    if(!map || !appContext) {
        return {};
    }
    const center = map.getCenter();
    const parameters = parseMapQuerystringParameters();

    const layerGroupIds = appContext.layerGroups?.filter(o => o.visible).map(o => o.layerGroupId);
    
    const decimalMultiplier = 100000;

    const result = {
        zoom: map.getZoom(),
        lat: Math.round(decimalMultiplier * center.lat) / decimalMultiplier,
        lng: Math.round(decimalMultiplier * center.lng) / decimalMultiplier,
        background: appContext.selectedBackgroundMapLayerKey ?? parameters?.background,
        layers: layerGroupIds?.join('-') ?? parameters?.layers
    };
    
    return result;
};

export const setInitialBounds = (map : L.Map) => {
    const mapParameters = parseMapQuerystringParameters();
    if(mapParameters?.lat && mapParameters?.lng) {
        map.setView([mapParameters.lat, mapParameters.lng], mapParameters.zoom);
    } else {
        map.fitBounds(swedenBoundingBox);
    }
};

export const parseMapQuerystringParameters = () : MapQuerystringParameters | undefined => {
    const qs = queryString.parse(window.location.search);
    if(qs.lat === undefined || qs.lng === undefined || qs.zoom === undefined) {
        return undefined;
    }
    const result : MapQuerystringParameters = {
        lat: Number(qs.lat),
        lng: Number(qs.lng),
        zoom: Number(qs.zoom),
        background: qs.background as string,
        layers: qs.layers as string
    };

    // fix incorrect linking from skogsmonitor.se
    if(result.layers === '9-6') {
        result.layers = '17-21-14';
    }

    return result;
};

export const showMetadataPopup = (map : L.Map, latlng : L.LatLng, areas : Area[], layerGroups : LayerGroup[] | undefined) : L.Popup => {
    const metadataContent = createMetadataContent(areas, layerGroups, 0);

    const singleAreaClass = areas.length === 1
        ? ' single-area'
        : '';

    const navigationButtonsMarkup =
        `<div class="navigation-buttons${singleAreaClass}"><button class="navigation-button-previous">&lt;</button><div class="navigation-label">1/${areas.length}</div><button class="navigation-button-next">&gt;</button></div>`;

    const popup = L.popup()
        .setLatLng(latlng)
        .setContent(`<div class="metadata-popup" data-index="0"><div class="metadata-content-container">${metadataContent}</div>${navigationButtonsMarkup}</div>`)
        .openOn(map);

    addNavigationEvents(popup, areas, layerGroups);

    return popup;
};

export const initLeafletDraw = (map : L.Map, events: LeafletDrawEvents) => {
    const drawLayerGroup = new L.FeatureGroup();
    map.addLayer(drawLayerGroup);
    const drawControlOptions = {
        position: 'topright',
        draw: {
            polyline: true,
            polygon: true,
            circle: true,
            marker: false,
            label: true
        },
        edit: {
            featureGroup: drawLayerGroup,
            remove: true
        }
    };
    const drawControl = (L.control as any).draw(drawControlOptions);
    map.addControl(drawControl);

    const onDrawStop = (e : any) => {
        const shapes : LeafletDrawShape[] = [];
        map.eachLayer(layer => {
            const shape = createShape(layer);
            if(shape) {
                shapes.push(shape!);
            }
        })
        events.onChangeShapes(shapes);
    };

    map.on((L as any).Draw.Event.CREATED, (e : any) => {
        if(e.layerType === 'label') {
            events.onAddLabel(e, drawLayerGroup);
        } else {
            drawLayerGroup.addLayer(e.layer);
        }
    });

    map.on((L as any).Draw.Event.DRAWSTOP, onDrawStop);

    map.on((L as any).Draw.Event.EDITSTOP, onDrawStop);

    map.on((L as any).Draw.Event.DELETESTOP, (e : any) => {
        events.onChangeShapes([]);
    });
};

const createShape = (layer : any) : LeafletDrawShape | undefined => {
    const layerType = getLayerType(layer);
    switch(layerType) {
        case 'circle': 
            return {
                type: 'circle',
                coordinates: [ createCoordinate(layer._latlng) ],
                radius: layer._mRadius
            };

        case 'rectangle':
            const s = layer._bounds._southWest.lat;
            const n = layer._bounds._northEast.lat;
            const w = layer._bounds._southWest.lng;
            const e = layer._bounds._northEast.lng;
            return {
                type: 'polygon',
                coordinates: [
                    { latitude: s, longitude: w },
                    { latitude: s, longitude: e },
                    { latitude: n, longitude: e },
                    { latitude: n, longitude: w }
                ]
            };

        case 'polygon':
            return {
                type: 'polygon',
                coordinates: layer._latlngs[0].map(createCoordinate)
            };
    }

    return undefined;
};

const getLayerType = (layer : any) : string | undefined => (layer as any)?.editing?._shape?.options?.type ?? (layer as any)?.editing?._poly?.options?.type;

const createCoordinate = (latlng : any) : Coordinate => ({ latitude: latlng.lat, longitude: latlng.lng });